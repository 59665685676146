<script setup>
  import menu from "~/data/footer-menu.json";
  import {useAcfOption} from "~/composables/ApiHelper";
  import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
  import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

  const socialMedias = await useAcfOption('social_media');
  const contactDetails = await useAcfOption('contact_details');
</script>

<template>
  <div class="bg-wh-brown footer-wrapper">
    <footer class="container-lg py-5 footer-block">
      <div class="row flex-column flex-md-row">
        <template v-for="item in menu?.menuItems?.nodes">
          <div class="footer-section col-12 col-xs-6 col-md-4 col-xl-2 mb-3">
            <div class="footer-title">
              <div class="title-expand">
                <h4 class="mb-md-3">
                  <a class="text-decoration-none" :href="item?.uri">{{ item?.label }}</a>
                </h4>
                <FontAwesomeIcon :icon="faChevronDown" class="arrow-footer d-md-none"/>
              </div>
            </div>
            <ul class="nav flex-column d-none d-md-flex">
              <template v-for="child in item?.childItems?.nodes">
                <li class="nav-item mb-md-2 mt-md-0 my-3">
                  <a :href="child?.uri" class="nav-link p-0 text-muted">{{ child?.label }}</a>
                </li>
              </template>
            </ul>
          </div>
        </template>
        <div class="footer-section col-12 col-xs-6 col-md-4 col-xl-2">
          <div class="footer-title">
            <div class="title-expand">
              <h4 class="mb-md-3">Contact Us</h4>
              <FontAwesomeIcon :icon="faChevronDown" class="arrow-footer d-md-none"/>
            </div>
          </div>
          <ul class="nav flex-column d-none d-md-flex">
            <li class="nav-item mb-2">
              <a :href="`tel:${contactDetails?.contact_number}`" class="nav-link p-0 text-muted">
                {{contactDetails?.contact_number}}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a :href="`mail:${contactDetails?.email_address}`" class="nav-link p-0 text-muted">
                {{contactDetails?.email_address}}
              </a>
            </li>
            <li class="nav-item mb-2 text-muted">-</li>
            <li class="nav-item mb-2">
              <span class="nav-link p-0 text-muted">{{contactDetails?.address1}}</span>
            </li>
            <li class="nav-item mb-2">
              <span class="nav-link p-0 text-muted">{{contactDetails?.address2}}</span>
            </li>
            <li class="nav-item mb-2">
              <a :href="contactDetails?.direction" class="nav-link p-0 text-muted">Get Directions</a>
            </li>
            <li class="nav-item mb-2 text-muted">-</li>
            <li class="nav-item mb-2">
              <span class="nav-link p-0 text-muted">{{contactDetails?.open_days}}</span>
            </li>
            <li class="nav-item mb-2">
              <span class="nav-link p-0 text-muted">{{contactDetails?.open_hours}}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="row footer-nav mt-md-0 mt-4">
        <div class="col-12 d-none d-md-block"><hr/></div>
        <div class="col-12 col-xl-4 order-3 order-xl-1 d-flex flex-column align-items-start mt-md-3 mt-xl-0">
          <p class="text-uppercase mb-0 text-uppercase mb-0 mt-4 mt-md-0">
            &copy; {{ new Date().getFullYear() }} Worthington Homes. All rights reserved.
          </p>
          <p class="text-uppercase website-dev">
            <a href="https://www.mage2.com.au/" target="_blank">WEBSITE BY MAGE2</a>
          </p>
        </div>
        <div class="col-12 col-md-6 col-xl-4 order-1 order-xl-2 mt-3 mt-md-0">
          <ul class="nav flex-column flex-md-row justify-content-start justify-content-xl-center">
            <template v-for="socialMedia in socialMedias">
              <li v-if="socialMedia?.show_in_footer" class="nav-item border-bottom mb-2 mb-md-0">
                <a class="nav-link text-uppercase d-flex footer-link px-0 px-md-2 mb-2 mb-md-0 d-flex align-items-center"
                   :href="socialMedia?.url">
                  {{socialMedia?.name}}
                  <NuxtImg loading="lazy"
                           src="/arrow.svg" :alt="socialMedia?.name + ' arrow'"
                           quality="80" height="20" width="30"
                           class="ms-auto d-inline-block d-md-none"/>
                </a>
              </li>
            </template>
          </ul>
        </div>
        <div class="col-12 col-md-6 col-xl-4 order-2 order-xl-3">
          <ul class="nav flex-column flex-md-row justify-content-md-end">
            <li class="nav-item border-bottom mb-2 mb-md-0">
              <a class="nav-link text-uppercase d-flex footer-link px-0 px-md-2 mb-2 mb-md-0 d-flex align-items-center"
                 href="/privacy-policy">
                Privacy
                <NuxtImg loading="lazy"
                         src="/arrow.svg" alt="Privacy arrow"
                         quality="80" height="20" width="30"
                         class="ms-auto d-inline-block d-md-none"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>
